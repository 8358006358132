const global = {
    domainUrl: process.env.VUE_APP_BEEREADERS_API_URL,
    BASE_ASSETS_URL: process.env.VUE_APP_S3RESOURCES,
    BASEURL_BOOKIMAGES: `${process.env.VUE_APP_S3RESOURCES}/lbr/image/`,
    BASE_URL_BOOK: `${process.env.VUE_APP_S3RESOURCES}/lbr/book/`,
    BASE_URL_SOUNDPIECES: `${process.env.VUE_APP_S3RESOURCES}/lbr/sounds`,
    GAMIFICATION_SOUNDS: `${process.env.VUE_APP_S3RESOURCES}/lbr/sounds/UI_sounds/`,
    DICTIONARY_URL_API: 'https://api.dictionaryapi.dev/api/v1/entries/es/',
    ZEND_MUSIC_URI: `${process.env.VUE_APP_S3RESOURCES}/lbr/sounds/zen/`,
    WILDCARD_PRICE: 30,
    ZEND_SOUNDS: [
        'Relajacion_A.mp3',
        'Relajacion_B.mp3',
        'Relajacion_C.mp3',
        'Relajacion1_A.mp3',
        'Relajacion1_B.mp3',
        'Relajacion1_C.mp3',
        'Relajacion2_A.mp3',
        'Relajacion1_B.mp3',
        'Relajacion1_C.mp3',
        'Relajacion3_A.mp3',
        'Relajacion3_B.mp3',
        'Relajacion3_C.mp3',
    ],
    levelScale: [
        {
            level: '1',
            valueFrom: 0,
            valueTo: 19,
        },
        {
            level: '2',
            valueFrom: 20,
            valueTo: 39,
        },
        {
            level: '3',
            valueFrom: 40,
            valueTo: 79,
        },
        {
            level: '4',
            valueFrom: 80,
            valueTo: 159,
        },
        {
            level: '5',
            valueFrom: 160,
            valueTo: 319,
        },
        {
            level: '6',
            valueFrom: 320,
            valueTo: 639,
        },
        {
            level: '7',
            valueFrom: 640,
            valueTo: 1279,
        },
        {
            level: '8',
            valueFrom: 1280,
            valueTo: 2559,
        },
        {
            level: '9',
            valueFrom: 2560,
            valueTo: 5119,
        },
        {
            level: '10',
            valueFrom: 5120,
            valueTo: 10240,
        },
    ],
}

export default global
