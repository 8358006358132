<template>
    <div class="d-flex justify-start align-start">
        <v-sheet
            class="rounded-circle transition-swing d-flex justify-center align-center"
            :color="classColor"
            rounded
            shaped
            v-if="!avatar"
            :style="{
                width: `${size}vh`,
                height: `${size}vh`,
            }"
        >
            <span class="white--text" :style="{ fontSize: fontSize }">{{ iconName }}</span>
        </v-sheet>
        <img
            :src="`${$utils.globalConstants.BASE_ASSETS_URL}/avatar/${avatar.name}/${avatar.value}`"
            alt="userAvatar"
            :class="'rounded-circle transition-swing'"
            :style="{
                width: `${size}vh`,
                height: `${size}vh`,
            }"
            v-else
        />
    </div>
</template>

<script>
export default {
    name: 'UserAvatar',
    props: {
        avatar: { type: Object, default: () => null },
        userName: { type: String, default: '' },
        size: { type: [String, Number], default: '5' },
        fontSize: { type: String, default: '12px' },
    },
    data() {
        return {
            colors: [
                '#E53935',
                '#D50000',
                '#4A148C',
                '#AA00FF',
                '#3F51B5',
                '#64B5F6',
                '#00796B',
                '#01579B',
            ],
            classColor: '',
        }
    },
    mounted() {
        this.classColor = this.colors[Math.floor(this.colors.length * Math.random())]
    },
    computed: {
        iconName() {
            return `${this.$utils.getFirstLetter(this.userName.split(' ')[0])}
            ${this.$utils.getFirstLetter(this.userName.split(' ')[1])}`
        },
    },
}
</script>

<style scoped>
.rounded-circle {
    border-radius: 500px !important;
}
</style>
