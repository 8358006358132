export default {
    NOV: 'Novela',
    CUE: 'Cuento',
    MIT: 'Mito',
    LEY: 'Leyenda',
    FAB: 'Fábula',
    TEA: 'Teatro',
    EPI: 'Epístola',
    INF: 'Informativo',
    BIO: 'Biografía',
    POE: 'Poema',
    COM: 'Cómic',
    ROM: 'Romance',
    EPO: 'Epopeya',
    TRA: 'Tragedia',
}
