export default {
    NOV: 'Novel',
    CUE: 'Story',
    MIT: 'Myth',
    LAW: 'Legend',
    FAB: 'Fable',
    TEA: 'Theater',
    EPI: 'Epistle',
    INF: 'Informative',
    BIO: 'Biography',
    POE: 'Poem',
    COM: 'Comic',
    ROM: 'Romance',
    EPO: 'Epic',
    TRA: 'Tragedy',
}
